import { Box, type SxProps, type Theme, styled } from '@mui/material'

export const composeContainerSx = (theme: Theme): SxProps<Theme> => ({
  // The compose container take up all the space it needs
  flexShrink: 0,
  // Also use minimum height to keep the UI stable when children components change
  minHeight: '200px',
  padding: theme.spacing(1),
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: theme.palette.background.paper
})

export const LeftSideContainer = styled(Box)(() => ({
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column'
}))

export const AttachmentsAndInputContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  [theme.breakpoints.up('xs')]: {
    gap: theme.spacing(0.5)
  },
  [theme.breakpoints.up('sm')]: {
    gap: theme.spacing(1)
  }
}))

export const AttachmentsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(1),
  alignItems: 'center'
}))

export const SendButtonContainer = styled(Box)(({ theme }) => ({
  width: '75px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-end'
}))
