import { Box, TableCell, Typography, styled } from '@mui/material'

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 1
}))

export const IssueTitleContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}))

export const IssueTypeTypography = styled(Typography)(() => ({
  fontSize: 22,
  fontWeight: 'bold'
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  alignItems: 'center'
}))
