import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { Tooltip, useTheme, type ButtonProps } from '@mui/material'
import { outlineButtonSx } from 'components/buttons/OutlinedButton'
import { useGlobals } from 'context/GlobalsContext'
import { type ComparativeAnalysisOption, type ComparisonTypeId } from 'features/comparative-analysis'
import getSelectedComparativeAnalysisOptions from 'features/comparative-analysis/api/getComparativeAnalysisOptions'
import { type Globals } from 'features/globals'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction, type AnalysisTaskParams } from 'features/user-input-form/types'
import { NestedDropdown, type MenuItemData } from 'mui-nested-menu'
import { useIntl } from 'react-intl'

interface AnalyzeTaskMenuProps {
  label: string
  icon: React.ReactNode
  disabled: boolean
}

/**
 * When the user clicks on the Analyze task button, this nested menu will be displayed
 * with sub-tasks and their options to choose from. This is a way to make the UI
 * faster than having multiple select dropdowns to choose from, one after the other.
 */
const AnalyzeTaskMenu = (
  { label, icon, disabled }: AnalyzeTaskMenuProps
): JSX.Element => {
  const globals = useGlobals() as Globals
  const theme = useTheme()
  const intl = useIntl()
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const updateTaskParams = (taskParams: AnalysisTaskParams): void => {
    setUserInputForm({
      ...userInputForm,
      mainTask,
      taskParams: {
        ...userInputForm.taskParams,
        ...taskParams
      }
    })
  }

  const setComparativeType = (comparisonTypeId: ComparisonTypeId | null): void => {
    const options: ComparativeAnalysisOption = getSelectedComparativeAnalysisOptions(userInputForm)
    updateTaskParams({
      action: AnalysisTaskAction.ComparativeAnalysis,
      comparativeAnalysisOptions: {
        ...options,
        comparisonTypeId
      }
    })
  }

  const mainTask = 'Analyze'
  const comparisonTypes = globals.taskParams.analyze.comparativeAnalysis.comparisonTypes

  /**
   * Menu items data for the Analyze task menu, including sub-tasks and their actions.
   * For example, "Contract analysis" -> "Comparative analysis" -> "Term sheet vs. Contract"
   */
  const menuItemsData: MenuItemData = {
    label: intl.formatMessage({
      id: `app.main-task.${mainTask}`,
      defaultMessage: mainTask
    }),
    leftIcon: icon,
    items: [
      {
        label: intl.formatMessage({
          id: 'app.analyze-task.sub-task.Contract analysis',
          defaultMessage: 'Contract analysis'
        }),
        leftIcon: icon,
        items: Object.values(AnalysisTaskAction).map((action, idx) => (
          {
            label: intl.formatMessage({
              id: `app.analyze-task.contract-analysis.action.${action}`,
              defaultMessage: action
            }),
            leftIcon: icon,
            callback: () => {
              updateTaskParams({
                action
              })
            },
            items: (
              action === AnalysisTaskAction.ComparativeAnalysis
                ? comparisonTypes.map((comparisonType, index) => (
                  {
                    label: intl.formatMessage({
                      id: `app.comparative-analysis.type-select.options.${comparisonType.id}`,
                      defaultMessage: comparisonType.id
                    }),
                    leftIcon: <ContentCopyIcon />,
                    callback: () => {
                      setComparativeType(comparisonType.id)
                    }
                  }
                ))
                : undefined
            )
          }
        ))
      },
      {
        label: intl.formatMessage({
          id: 'app.analyze-task.sub-task.Brief analysis',
          defaultMessage: 'Brief analysis'
        }),
        leftIcon: icon,
        disabled: true
      },
      {
        label: intl.formatMessage({
          id: 'app.analyze-task.sub-task.Doc review',
          defaultMessage: 'Doc review'
        }),
        leftIcon: icon,
        disabled: true
      },
      {
        label: intl.formatMessage({
          id: 'app.analyze-task.sub-task.Other doc analysis',
          defaultMessage: 'Other doc analysis'
        }),
        leftIcon: icon,
        disabled: true
      }
    ]
  }

  const buttonProps: Partial<ButtonProps> = {
    variant: 'outlined',
    startIcon: icon,
    sx: outlineButtonSx(theme)
  }

  // FIXME: copied from TaskButton, should be refactored
  const translatedTaskLabel = intl.formatMessage({
    id: `app.main-task.${label}`,
    defaultMessage: label
  })

  // FIXME: copied from TaskButton, should be refactored
  const tooltip = (
    disabled
      ? intl.formatMessage({
        id: 'app.task-button.coming-soon',
        defaultMessage: 'Coming soon...'
      })
      : intl.formatMessage(
        {
          id: 'app.task-button.click-to-perform-task',
          defaultMessage: 'Click to perform a {task} task'
        },
        { task: translatedTaskLabel }
      )
  )
  return (
    <Tooltip title={tooltip} placement={'top'}>
      <NestedDropdown
        menuItemsData={menuItemsData}
        ButtonProps={buttonProps}
      />
    </Tooltip>
  )
}

export default AnalyzeTaskMenu
