import { styled, TableCell, type Theme } from '@mui/material'

const baseTableCellStyles = ({ theme }: { theme: Theme }): Record<string, any> => ({
  width: '50%',
  verticalAlign: 'top',
  margin: 0,
  padding: theme.spacing(1),
  borderBottom: 'none'
})

export const StyledTableCell = styled(TableCell)(baseTableCellStyles)

// Same as StyledTableCell, but with bold text and centered
export const StyledHeaderTableCell = styled(TableCell)(({ theme }) => ({
  ...baseTableCellStyles({ theme }),
  fontWeight: 'bold',
  textAlign: 'center'
}))
