/**
 * Hook to get and update the options for the comparative analysis,
 * which are nested within the user input form state.
 */

import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction } from 'features/user-input-form/types'
import getComparativeAnalysisOptions from '../api/getComparativeAnalysisOptions'
import { type ComparativeAnalysisOption } from '../types'

interface UseComparativeAnalysisOptions {
  options: ComparativeAnalysisOption
  updateOptions: (update: Partial<ComparativeAnalysisOption>) => void
}

const useComparativeAnalysisOptions = (): UseComparativeAnalysisOptions => {
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const options: ComparativeAnalysisOption = getComparativeAnalysisOptions(userInputForm)

  const updateOptions = (update: Partial<ComparativeAnalysisOption>): void => {
    setUserInputForm({
      ...userInputForm,
      taskParams: {
        action: AnalysisTaskAction.ComparativeAnalysis,
        comparativeAnalysisOptions: {
          ...options,
          ...update
        }
      }
    })
  }

  return { options, updateOptions }
}

export default useComparativeAnalysisOptions
