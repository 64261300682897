import { type UserInputForm, AnalysisTaskAction, SpecificAnalysisOption } from 'features/user-input-form/types'
import { useIntl } from 'react-intl'
import { getEnumKeyByValue } from '../../../services/utils'
import OptionsSelect, { type Option } from './OptionsSelect'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

const SpecificAnalysisOptionsSelect: React.FC<Props> = (
  { userInputForm, onChange }: Props
) => {
  const intl = useIntl()
  const label = intl.formatMessage({
    id: 'app.specific-analysis-options-select.label',
    defaultMessage: 'Select options'
  })
  return (
    <OptionsSelect
      items={
        Object.values(SpecificAnalysisOption).map((option, idx) => {
          // id 0 is reserved for 'Select all' option
          return { id: idx + 1, name: option }
        })
      }
      label={label}
      onChange={(selectedOps) => {
        const specificAnalysisOptions: SpecificAnalysisOption[] = selectedOps.map((option: Option) =>
          getEnumKeyByValue(SpecificAnalysisOption, option.name)
        ).filter((option) => option !== undefined) as SpecificAnalysisOption[]
        onChange({
          ...userInputForm,
          taskParams: {
            action: AnalysisTaskAction.SpecificAnalysis,
            specificAnalysisOptions
          }
        })
      }}
    />
  )
}

export default SpecificAnalysisOptionsSelect
