import {
  IconButton,
  Tooltip,
  useTheme
} from '@mui/material'
import type React from 'react'
import { MagnetIcon } from './MagnetIcon'
import { useIntl } from 'react-intl'
import iconButtonSx from 'components/buttons/ToggleIconButton.styles'

interface Props {
  snap: boolean
  setSnap: (snap: boolean) => void
}

const SnapToggleButton: React.FC<Props> = ({ snap, setSnap }: Props) => {
  const intl = useIntl()
  const theme = useTheme()
  const title = (
    snap
      ? intl.formatMessage({
        id: 'app.snap-toggle-button.enable-tooltip',
        defaultMessage: 'Enable snapping text selection to words'
      })
      : intl.formatMessage({
        id: 'app.snap-toggle-button.disable-tooltip',
        defaultMessage: 'Disable snapping text selection to words'
      })
  )
  return <Tooltip title={title}>
    <IconButton onClick={() => { setSnap(!snap) }} sx={iconButtonSx(snap, theme.palette)}>
      <MagnetIcon color={'inherit'} />
    </IconButton>
  </Tooltip>
}

export default SnapToggleButton
