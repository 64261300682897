const isObject = (value: any): boolean => {
  return value !== null && value !== undefined && typeof value === 'object' && !Array.isArray(value)
}

export const camelToSnakeCase = (str: string): string => str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

export const snakeToCamelCase = (str: string): string => str.replace(/_([A-Za-z])/g, g => g[1].toUpperCase())

export const transformKeys = (obj: Record<string, any>, fn: (str: string) => string): Record<string, any> => {
  if (isObject(obj)) {
    return Object.keys(obj).reduce<Record<string, any>>((result, key) => {
      // Recursively apply the transformation to nested objects
      result[fn(key)] = transformKeys(obj[key], fn)
      return result
    }, {})
  } else if (Array.isArray(obj)) {
    return obj.map(o => transformKeys(o, fn))
  }
  return obj
}

export const transformKeysToSnakeCase = (obj: Record<string, any>): Record<string, any> => transformKeys(obj, camelToSnakeCase)

export const transformKeysToCamelCase = (obj: Record<string, any>): Record<string, any> => transformKeys(obj, snakeToCamelCase)

export const arrEquals = (arr1: any[], arr2: any[]): boolean => {
  if (arr1.length !== arr2.length) return false
  return arr1.every((val, i) => val === arr2[i])
}

const wordCharsAtBegPattern = /^([\p{Letter}0-9]+)/u
const wordCharsAtEndPattern = /([\p{Letter}0-9]+)$/u

export const snapSelectionToWord = (): void => {
  const sel = window.getSelection()
  if (sel === null) return

  if (sel.anchorNode === null || sel.focusNode === null) {
    throw new Error('anchorNode or focusNode is null')
  }

  // Detect if selection is backwards
  const range = document.createRange()
  range.setStart(sel.anchorNode, sel.anchorOffset)
  range.setEnd(sel.focusNode, sel.focusOffset)
  const backwards = range.collapsed
  range.detach()

  // Get text before/after selection to see if we need to snap
  const [textBefore, textAfter] = getOuterRangeText(sel)

  // Check the initial selection for non-word characters at the beginning/end.
  const wordCharsBefore = textBefore.match(wordCharsAtEndPattern)
  const wordCharsAfter = textAfter.match(wordCharsAtBegPattern)

  // Collapse selection to the beginning (inverting if backwards)
  const startNode = backwards ? sel.focusNode : sel.anchorNode
  const startOffset = backwards ? sel.focusOffset : sel.anchorOffset
  const endNode = backwards ? sel.anchorNode : sel.focusNode
  const endOffset = backwards ? sel.anchorOffset : sel.focusOffset
  sel.collapse(startNode, startOffset)

  // Move backward until the next char of not a word character
  if (wordCharsBefore !== null) {
    const nbCharsBefore = wordCharsBefore[0].length
    for (let i = 0; i < nbCharsBefore; i++) {
      sel.modify('move', 'backward', 'character')
    }
  }

  sel.extend(endNode, endOffset)

  // Extend forward until the next char of not a word character
  if (wordCharsAfter !== null) {
    const nbCharsAfter = wordCharsAfter[0].length
    for (let i = 0; i < nbCharsAfter; i++) {
      sel.modify('extend', 'forward', 'character')
    }
  }
}

const getOuterRangeText = (selection: Selection): [string, string] => {
  const rangeBefore = document.createRange()
  const rangeAfter = document.createRange()
  const r = selection.getRangeAt(0)

  rangeBefore.setStart(r.startContainer, 0)
  rangeBefore.setEnd(r.startContainer, r.startOffset)
  const textBefore = rangeBefore.toString()

  if (r.endContainer.nodeType === Node.TEXT_NODE) {
    const endContainerText = r.endContainer.textContent
    if (endContainerText === null) {
      throw new Error('textContent is null even though the nodeType is TEXT_NODE')
    }
    rangeAfter.setStart(r.endContainer, r.endOffset)
    rangeAfter.setEnd(r.endContainer, endContainerText.length)
    const textAfter = rangeAfter.toString()

    return [textBefore, textAfter]
  }
  return [textBefore, '']
}

export const getEnumKeyByValue = <T>(enumObj: Record<string, T>, enumValue: any): T | undefined => {
  const findRes = Object.keys(enumObj).find(key => enumObj[key] === enumValue)
  if (findRes === undefined) return undefined
  return enumObj[findRes]
}

export const capitalizeFirstLetter = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)
