import { MenuItem, Select } from '@mui/material'
import { type UserInputForm, AnalysisTaskAction, type AnalysisTaskParams } from 'features/user-input-form/types'
import { useIntl } from 'react-intl'

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

// Customize the Select component
const AnalysisTaskActionSelect: React.FC<Props> = (
  { userInputForm, onChange }: Props
) => {
  const intl = useIntl()

  const renderValue = (value: AnalysisTaskAction | ''): string => (
    value === ''
      ? intl.formatMessage({
        id: 'app.analysis-task-action-select.placeholder',
        defaultMessage: 'Select an action'
      })
      : intl.formatMessage({
        id: `app.analyze-task.contract-analysis.action.${value}`,
        defaultMessage: value
      })
  )

  const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined

  return (
    <Select
      value={taskParams?.action ?? ''}
      displayEmpty
      renderValue={renderValue}
      onChange={(event) => {
        const action = event.target.value as AnalysisTaskAction
        onChange({
          ...userInputForm,
          taskParams: {
            ...userInputForm.taskParams,
            action
          }
        })
      }}
    >
      {/* Show a menu item for each analysis action */}
      {Object.values(AnalysisTaskAction).map((action, idx) => (
        <MenuItem key={idx} value={action}>
          {
            intl.formatMessage({
              id: `app.analyze-task.contract-analysis.action.${action}`,
              defaultMessage: action
            })
          }
        </MenuItem>
      ))}
    </Select>
  )
}

export default AnalysisTaskActionSelect
