import { Table, TableBody, TableHead, TableRow } from '@mui/material'
import { type Correction } from '../types'
import { StyledTableCell } from './DocReviewIssueDisplay.styles'

interface Props {
  corrections?: Correction[]
}

const CorrectionsDisplay = ({ corrections }: Props): JSX.Element => (
  <div className='corrections-section'>
    <h3>{'Suggested corrections'}</h3>
    <Table>
      <TableHead>
        <TableRow>
          <StyledTableCell>{'Before'}</StyledTableCell>
          <StyledTableCell>{'After'}</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {corrections?.map((correction, idx) => (
          <TableRow key={idx}>
            <StyledTableCell>{correction.before}</StyledTableCell>
            <StyledTableCell>{correction.after}</StyledTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </div>
)

export default CorrectionsDisplay
