import { Typography } from '@mui/material'
import { type AttachmentListItem } from 'features/documents'
import { type IssueType, type DocReviewIssue } from '../types'
import DocReviewIssueDisplay from './DocReviewIssueDisplay'
import { useIntl } from 'react-intl'

interface Props {
  typeToResults: Map<IssueType, DocReviewIssue[]>
  reviewedDoc: AttachmentListItem | null
  referenceDoc: AttachmentListItem | null
  attachmentIdToAttachmentListItem: Map<string, AttachmentListItem>
}

const ContextualIssuesDisplay: React.FC<Props> = ({
  typeToResults,
  reviewedDoc,
  referenceDoc,
  attachmentIdToAttachmentListItem
}: Props) => {
  const intl = useIntl()
  return (
    <div className='contextual-issues'>
      {
        Array.from(typeToResults).map(([type, _results]: [IssueType, DocReviewIssue[]]) => {
          return (
            <div className='doc-review-results-type' key={type}>
              {/* For now, this would be "Deviation Analysis" */}
              <Typography variant='h3'>
                {
                  intl.formatMessage({
                    id: `app.doc-review-results-type.${type}.title`,
                    defaultMessage: `${type} Analysis`
                  })
                }
              </Typography>
              <Typography>
                {
                  intl.formatMessage({
                    id: `app.doc-review-results-type.${type}.description`,
                    defaultMessage: `Copilex has identified the following ${type}s between the Reviewed Document and the Reference Document.`
                  })
                }
              </Typography>
              {
                _results.map((result, idx) => {
                  return (
                    <div className='doc-review-results-item' key={idx}>
                      <DocReviewIssueDisplay
                        idx={idx}
                        result={result}
                        reviewedDoc={reviewedDoc}
                        referenceDoc={referenceDoc}
                        attachmentIdToAttachmentListItem={attachmentIdToAttachmentListItem}
                      />
                    </div>
                  )
                })
              }
            </div>
          )
        })
      }
    </div>
  )
}

export default ContextualIssuesDisplay
