import { type AttachmentListItem } from 'features/documents'
import { type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { type DocReviewIssue, type IssueCategory, type IssueType, type OtherDocReviewResult, type DocReviewResults } from '../types'
import ContextualIssuesDisplay from './ContextualIssuesDisplay'
import { StyledBox } from './DocReviewResultsDisplay.styles'
import InternalIssuesDisplay from './InternalIssuesDisplay'
import OtherDocReviewResultDisplay from './OtherDocReviewResultDisplay'

interface Props {
  results: DocReviewResults
  /**
   * The UserInputForm contains parameters that we need to use when displaying in the result,
   * such as which attachment was the reviewed or reference document,
   * and what their corresponding filenames are.
   */
  userInputForm: UserInputForm
}

const DocReviewResultsDisplay: React.FC<Props> = ({ results, userInputForm }: Props) => {
  // Map each attachment ID to the corresponding attachment list item
  const attachments = userInputForm.attachments
  const attachmentIdToAttachmentListItem = new Map<string, AttachmentListItem>()
  attachments.forEach((attachment) => {
    attachmentIdToAttachmentListItem.set(attachment.id, attachment)
  })

  // Extract information specific to comparative analysis
  const comparativeAnalysisOptions = (
    (userInputForm?.taskParams as AnalysisTaskParams)?.comparativeAnalysisOptions ?? null
  )
  const reviewedDocId = comparativeAnalysisOptions?.attachmentsIds[0] ?? null
  const referenceDocId = comparativeAnalysisOptions?.attachmentsIds[1] ?? null
  // Get the corresponding AttachmentListItem object
  const reviewedDoc = reviewedDocId !== null ? attachmentIdToAttachmentListItem.get(reviewedDocId) : null
  const referenceDoc = referenceDocId !== null ? attachmentIdToAttachmentListItem.get(referenceDocId) : null
  if (reviewedDoc === undefined || referenceDoc === undefined) {
    throw new Error('Could not find attachment list item for the reviewed or reference document in the attachment list')
  }

  // Regroup the results based on their category (e.g., "Internal"), and then their type (e.g., "Typographical error"),
  // so that we can display them in a structured way.
  // Results that are not structured (no "category" field) will be displayed separately.
  const categoryToTypeToResults = new Map<IssueCategory, Map<IssueType, DocReviewIssue[]>>()
  const unstructuredResults: OtherDocReviewResult[] = []
  results.forEach((result) => {
    if ('category' in result) {
      const category: IssueCategory = result.category
      const type: IssueType = result.type
      if (!categoryToTypeToResults.has(category)) {
        categoryToTypeToResults.set(category, new Map<IssueType, DocReviewIssue[]>())
      }
      const typeToResults = categoryToTypeToResults.get(category) as Map<IssueType, DocReviewIssue[]>
      if (!typeToResults.has(type)) {
        typeToResults.set(type, [])
      }
      const _results = typeToResults.get(type) as DocReviewIssue[]
      _results.push(result)
      typeToResults.set(type, _results)
    } else {
      unstructuredResults.push(result)
    }
  })

  const internalTypeToResults = categoryToTypeToResults.get('Internal')
  const contextualTypeToResults = categoryToTypeToResults.get('Contextual')

  return (
    <StyledBox className='doc-review-results'>
      {/*
        Display structured results first, if any.
        Internal issues and contextual issues both have
        their specific components to display
      */}
      {
        internalTypeToResults !== undefined && (
          <InternalIssuesDisplay typeToResults={internalTypeToResults} />
        )
      }
      {
        contextualTypeToResults !== undefined && (
          <ContextualIssuesDisplay
            typeToResults={contextualTypeToResults}
            reviewedDoc={reviewedDoc}
            referenceDoc={referenceDoc}
            attachmentIdToAttachmentListItem={attachmentIdToAttachmentListItem}
          />
        )
      }
      {/* Display unstructured results, if any */}
      {
        unstructuredResults.length > 0 && (
          <div className='doc-review-other-results'>
            {
              unstructuredResults.map((result, idx) => {
                return (
                  <div className='doc-review-other-results-item' key={idx}>
                    <OtherDocReviewResultDisplay result={result} />
                  </div>
                )
              })
            }
          </div>
        )
      }
    </StyledBox>
  )
}

export default DocReviewResultsDisplay
