import { Box, styled, TableCell, type Theme } from '@mui/material'

export const StyledBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 2
}))

const baseTableCellStyles = ({ theme }: { theme: Theme }): Record<string, any> => ({
  verticalAlign: 'top',
  margin: 0,
  padding: theme.spacing(1),
  borderBottom: 'none'
})

export const LocationCell = styled(TableCell)(({ theme }) => ({
  ...baseTableCellStyles,
  width: '20%'
}))

export const EmptyLocationCell = styled(TableCell)(({ theme }) => ({
  ...baseTableCellStyles,
  width: '0%'
}))

export const DetailsCell = styled(TableCell)(({ theme }) => ({
  ...baseTableCellStyles,
  width: '80%'
}))
