import { type UUID } from 'crypto'
import { type DocType } from 'features/documents'

export type IssueCategory = 'Internal' | 'Contextual' | 'Compliance'

export type IssueType = SanityCheckOption | 'Deviation'

export type Criticality = 'low' | 'medium' | 'high'

/**
 * Options for the sanity check.
 */
export enum SanityCheckOption {
  TypographicalErrors = 'Typographical errors',
  InternalCrossReferenceCheck = 'Internal cross-reference check',
  NumberingIssues = 'Numbering issues',
  Redundancies = 'Redundancies',
  InternalLogicErrors = 'Internal logic errors',
  AmbiguousTermsAndPhrases = 'Ambiguous terms and phrases',
  OmissionsOfImportantTerms = 'Omissions of important terms',
  // Deprecated options (kept for compatibility),
  ImpreciseDefinitions = 'Imprecise definitions',
  LogicalErrors = 'Logical errors',
  MathErrors = 'Math errors',
  StructuralErrors = 'Structural errors',
  LogicalContradictions = 'Logical contradictions',
}

/**
 * Reference to a document.
 * The filename is NOT included in the reference because it is not anonymized
 * and should end up in the final answer and thus history of the conversation
 * that models will have access to.
 */
export interface DocumentReference {
  /**
   * UUID of the document.
   */
  docId: UUID | null
  /**
   * Type of the document
   */
  docType: DocType | null
}

/**
 * Description of a passage that is an evidence of an issue in the analyzed contract,
 * which can be either in the contract itself or the reference to which it is compared.
 */
export interface Evidence {
  /**
   * Reference to the document where the passage is found.
   */
  docRef: DocumentReference | null
  /**
   * Location of the passage in the document, e.g., a section number.
   */
  location: string | null
  /**
   * The passage itself.
   */
  quote: string
  /**
   * Start and end indices of what should be highlighted in the passage.
   */
  markedSpan: [number, number] | null
}

/**
   * Description of a correction to be made to the analyzed document.
   */
export interface Correction {
  /**
   * The text to be replaced.
   */
  before: string
  /**
   * The text to replace with.
   */
  after: string
}

/**
 * Description of an issue in the analyzed document.
 */
export interface DocReviewIssue {
  /**
   * Category of the issue.
   */
  category: IssueCategory

  /**
   * Type of the issue.
   */
  type: IssueType

  /**
   * Level of severity of the issue.
   */
  criticality: Criticality | null

  /**
   * A few sentences to explain what the issue is (with the reasoning that was made).
   */
  explanation: string

  /**
   * Short title of the issue.
   */
  title: string | null

  /**
   * List of passages that should be retrieved to identify the error. May be empty if the issue is not related
  to a specific passage, for example if something is missing from the document.
   */
  evidences: Evidence[]

  /**
   * List of corrections to be made to the analyzed document to fix the issue.
   */
  corrections: Correction[] | null
}

/**
   * Result of a doc review task that is unstructured
   */
export interface OtherDocReviewResult {
  /**
   * Which analysis was selected, or user input if the option is not predefined.
   */
  option: string
  /**
   * Output of the model.
   */
  result: string
}

export type DocReviewResults = Array<DocReviewIssue | OtherDocReviewResult>
