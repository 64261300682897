import { Button, styled } from '@mui/material'

import { type Theme } from '@mui/material/styles'

export const outlineButtonSx = (theme: Theme): Record<string, any> => ({
  color: theme.palette.text.primary,
  ':hover': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  variant: 'outlined'
})

export const OutlinedButton = styled(Button)(({ theme }) => outlineButtonSx(theme))
