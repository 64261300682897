import SwapHorizIcon from '@mui/icons-material/SwapHoriz'
import { Box, styled, type Theme, Typography } from '@mui/material'
import Loading from 'components/Loading'
import { useGlobals } from 'context/GlobalsContext'
import { type AttachmentListItem } from 'features/documents'
import useListAttachments from 'features/documents/api/listAttachments'
import { type Globals } from 'features/globals'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import useComparativeAnalysisOptions from '../hooks/useComparativeAnalysisOptions'
import DocumentSelect from './DocumentSelect'
import getDocTypesFromComparisonType from '../api/getDocTypesFromComparisonType'
import StyledIconButton from 'components/buttons/StyledIconButton'
import { capitalizeFirstLetter } from 'services/utils'
import FileUploader from 'features/documents/components/FileUploader'

/**
 * Component for selecting which attached documents to use in a comparative analysis.
 */
const ComparativeAnalysisDocumentsSelect: React.FC = () => {
  const intl = useIntl()
  const globals = useGlobals() as Globals

  const { options, updateOptions } = useComparativeAnalysisOptions()

  // The list of documents to choose from is the list of all attachments
  const attachments = useListAttachments()

  // const [updateAttachmentMutations, setUpdateAttachmentMutations] = useState<
  // Array<ReturnType<typeof useUpdateAttachment> | null>
  // >([null, null])

  // React to changes in the attachments list
  useEffect(() => {
    // Update the mutation function for selected documents
    // setUpdateAttachmentMutations([0, 1].map((index) => {
    //   const attachmentId = options.attachmentsIds?.[index]
    //   return useUpdateAttachment({ attachmentId: attachmentId !== null ? attachmentId : undefined })
    // }))

    // If the attachments list now has 2 or more attachments,
    // and the user has not yet selected any, we will select the first 2
    // documents by defaults
    if (attachments !== undefined && attachments.length >= 2) {
      const prevAttachmentIds = options.attachmentsIds ?? [null, null]
      if (prevAttachmentIds[0] === null) {
        // If only the first attachment is missing,
        // select the first document that does not match the second one
        const secondAttachmentId = prevAttachmentIds[1]
        const firstAttachment = attachments.find((attachment) => attachment.id !== secondAttachmentId)
        if (firstAttachment !== undefined) {
          handleChange(0, firstAttachment)
        }
      } else if (prevAttachmentIds[1] === null) {
        // If only the second attachment is missing,
        // select the first document that does not match the first one
        const firstAttachmentId = prevAttachmentIds[0]
        const secondAttachment = attachments.find((attachment) => attachment.id !== firstAttachmentId)
        if (secondAttachment !== undefined) {
          handleChange(1, secondAttachment)
        }
      }
    }
    // Handle the case where a selected document is no longer available
    if (attachments !== undefined) {
      const attachmentIds = options.attachmentsIds
      if (attachmentIds !== undefined) {
        // Check for the first selected document
        const firstAttachmentId = attachmentIds[0]
        if (firstAttachmentId !== null && attachments.find((attachment) => attachment.id === firstAttachmentId) === undefined) {
          handleChange(0, null)
        }
        // Check for the second selected document
        const secondAttachmentId = attachmentIds[1]
        if (secondAttachmentId !== null && attachments.find((attachment) => attachment.id === secondAttachmentId) === undefined) {
          handleChange(1, null)
        }
      }
    }
  }, [attachments, options, updateOptions])

  const handleChange = (index: number, attachment: AttachmentListItem | null): void => {
    const attachmentIds = updateAttachmentsIds(options.attachmentsIds, index, attachment?.id ?? null)
    updateOptions({
      attachmentsIds: attachmentIds
    })

    // // Update the document type of the selected document as well
    // if (options.comparisonTypeId === null) {
    //   throw new Error('Comparison type is not set, cannot determine document types')
    // }
    // const docType = getDocTypesFromComparisonType(options.comparisonTypeId)[index]
    // console.debug(`Changing docType for select doc ${index} to ${docType}`)
    // updateAttachmentMutations[index]?.mutate({
    //   update: {
    //     docType
    //   }
    // })
  }

  const swapSelectedDocuments = (): void => {
    if (options.attachmentsIds[0] === null || options.attachmentsIds[1] === null) {
      return
    }
    updateOptions({
      attachmentsIds: [options.attachmentsIds[1], options.attachmentsIds[0]]
    })
  }

  const updateAttachmentsIds = (
    prevAttachmentIds: Array<string | null>,
    index: number,
    attachmentId: string | null
  ): Array<string | null> => {
    if (prevAttachmentIds.length !== 2) {
      throw new Error(`Expected an array of 2, but got ${prevAttachmentIds.length}`)
    }
    if (index < 0 || index >= prevAttachmentIds.length) {
      throw new Error(`Invalid index: ${index}`)
    }
    // Copy the array to avoid mutating the original
    const attachmentIds = prevAttachmentIds.slice()
    // Prevent the same attachment from appearing twice
    // by replacing previous occurrences by null
    attachmentIds.forEach((id, i) => {
      if (id === attachmentId && i !== index) {
        attachmentIds[i] = null
      }
    })
    attachmentIds[index] = attachmentId
    return attachmentIds
  }

  // The UI will be disabled if there are fewer than 2 attachments,
  // because we first want the user to upload all the necessary attachments.
  const disabled = attachments === undefined || attachments.length < 2

  // The label color will be different as well if the UI is disabled
  const labelSx = {
    flexGrow: 0,
    flexShrink: 1,
    color: (theme: Theme) => disabled ? theme.palette.text.disabled : theme.palette.text.primary
  }

  const displayDocType = (whichDoc: 'reviewed' | 'reference'): string => {
    const index = whichDoc === 'reviewed' ? 0 : 1
    if (options.comparisonTypeId === null) {
      return ''
    }
    const docType = getDocTypesFromComparisonType(options.comparisonTypeId, globals)[index]
    return intl.formatMessage({
      id: `app.doc-type.${docType}`,
      defaultMessage: docType
    })
  }

  const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
    width: '100%'
  }))

  const DocumentSelectContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    flexBasis: '50%',
    flexGrow: 0,
    flexShrink: 1,
    alignItems: 'center',
    gap: theme.spacing(1)
  }))

  const Label = ({ whichDoc }: { whichDoc: 'reviewed' | 'reference' }): JSX.Element => (
    <Typography sx={labelSx}>
      {intl.formatMessage({
        id: `app.comparative-documents-select.label.${whichDoc}`,
        defaultMessage: `${capitalizeFirstLetter(whichDoc)} {docType}`
      }, {
        docType: displayDocType(whichDoc)
      })}
    </Typography>
  )

  if (attachments === undefined) {
    <Loading />
  }

  return (
    <Container id='comparative-analysis-documents-select'>
      <DocumentSelectContainer>
        <Label whichDoc={'reviewed'} />
        {
          // Selection for the first attachment
          <DocumentSelect
            index={0}
            attachmentId={options.attachmentsIds[0]}
            onChange={(attachment) => {
              handleChange(0, attachment)
            } }
            disabled={disabled}
          />
        }
        {/* FIXME: We would like to have the button here, but
            we have an issue with the modal closing itself whenever
            there is an update in the attachments */}
        {/* <FileUploader iconOnly /> */}
      </DocumentSelectContainer>
      <StyledIconButton onClick={() => { swapSelectedDocuments() }}>
        <SwapHorizIcon />
      </StyledIconButton>
      <DocumentSelectContainer>
        <Label whichDoc={'reference'} />
        {
          // Selection for the second attachment
          <DocumentSelect
            index={1}
            attachmentId={options.attachmentsIds[1]}
            onChange={(attachment) => {
              handleChange(1, attachment)
            }}
            disabled={disabled}
          />
        }
        {/* FIXME: Same as the issue above */}
        {/* <FileUploader iconOnly /> */}
      </DocumentSelectContainer>
    </Container>
  )
}

export default ComparativeAnalysisDocumentsSelect
