import { Typography } from '@mui/material'
import { type AttachmentListItem } from 'features/documents'
import { type Correction, type DocReviewIssue } from '../types'
import ComparisonOverview from './ComparisonOverview'
import CorrectionsDisplay from './CorrectionsDisplay'
import { StyledBox } from './DocReviewIssueDisplay.styles'
import IssueTitle from './IssueTitle'

interface Props {
  idx: number
  result: DocReviewIssue
  reviewedDoc: AttachmentListItem | null
  referenceDoc: AttachmentListItem | null
  attachmentIdToAttachmentListItem: Map<string, AttachmentListItem>
}

const DocReviewIssueDisplay: React.FC<Props> = ({
  idx,
  result,
  reviewedDoc,
  referenceDoc,
  attachmentIdToAttachmentListItem
}: Props) => {
  return (
    <StyledBox className='doc-review-issue'>
      <IssueTitle idx={idx} title={result.title} criticality={result.criticality} />
      <Typography>{result.explanation}</Typography>
      <ComparisonOverview
        evidences={result.evidences}
        reviewedDoc={reviewedDoc}
        referenceDoc={referenceDoc}
        attachmentIdToAttachmentListItem={attachmentIdToAttachmentListItem}
      />
      {/* <RelevantPassagesDisplay evidences={result.evidences} attachmentIdToAttachmentListItem={attachmentIdToAttachmentListItem} /> */}
      {
        ((result.corrections?.length ?? 0) > 0) && <CorrectionsDisplay corrections={result.corrections as Correction[]} />
      }
    </StyledBox>
  )
}

export default DocReviewIssueDisplay
