import {
  Table,
  TableBody,
  TableContainer,
  TableHead
} from '@mui/material'
import AttachmentItem from './AttachmentItem'
import { type AttachmentOrUploadListItem, type Limits } from '../types'

interface Props {
  attachmentsAndUploads: AttachmentOrUploadListItem[] | undefined
  limits: Limits
}

const UploadsTable: React.FC<Props> = ({
  attachmentsAndUploads,
  limits
}: Props) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          {/* <TableRow>
            <StyledTableCell sx={filenameCellSx}>
              {
                intl.formatMessage({
                  id: 'app.uploads-table.doc-title-header',
                  defaultMessage: 'Doc title'
                })
              }
            </StyledTableCell>
            <StyledTableCell sx={selectorCellSx}>
              {
                intl.formatMessage({
                  id: 'app.uploads-table.doc-type-header',
                  defaultMessage: 'Doc type{br}(soon automatic)'
                }, {
                  br: <br />
                })
              }
            </StyledTableCell>
            <StyledTableCell></StyledTableCell>
          </TableRow> */}
        </TableHead>
        <TableBody>
          {attachmentsAndUploads?.map((a, index) =>
            <AttachmentItem
              key={index}
              attachmentOrUpload={a}
              limits={limits}
            />
          )}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default UploadsTable
