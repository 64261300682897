import { Paper, Typography } from '@mui/material'
import { type Evidence } from '../types'
import { CellContainer, LocationTypography } from './EvidenceDisplay.styles'

interface Props {
  evidence: Evidence
  displayLocation?: boolean
}

const EvidenceDisplay = ({ evidence, displayLocation = true }: Props): JSX.Element => {
  return (
    <CellContainer>
      {displayLocation && <LocationTypography>
        {evidence.location ?? 'N/A'}
      </LocationTypography>}
      <Paper sx={{ padding: 1, margin: 0, border: 1 }}>
        {/* Display the quote, which can have multiple lines */}
        <Typography fontSize={12} whiteSpace={'pre-wrap'}>
          {evidence.quote}
        </Typography>
      </Paper>
    </CellContainer>
  )
}

export default EvidenceDisplay
