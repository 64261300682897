import { Box } from '@mui/material'
import { type OtherDocReviewResult } from '../types'

interface Props {
  result: OtherDocReviewResult
}

const OtherDocReviewResultDisplay: React.FC<Props> = ({ result }: Props) => {
  return (
    <Box className='other-doc-review-result'>
      <h3>{result.option}</h3>
      <p>{result.result}</p>
    </Box>
  )
}

export default OtherDocReviewResultDisplay
