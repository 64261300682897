import { createContext, type ReactNode, useContext, useState } from 'react'
import { type UserInputForm } from '../types'

interface UserInputFormContextType {
  userInputForm: Partial<UserInputForm>
  setUserInputForm: (userInputForm: Partial<UserInputForm>) => void
}

export const UserInputFormContext = createContext<UserInputFormContextType>(
  { userInputForm: {}, setUserInputForm: () => {} }
)

interface Props {
  children: ReactNode
}

export const UserInputFormProvider = ({ children }: Props): JSX.Element => {
  const [userInputForm, setUserInputForm] = useState<Partial<UserInputForm>>({})

  return (
    <UserInputFormContext.Provider value={{ userInputForm, setUserInputForm }}>
      {children}
    </UserInputFormContext.Provider>
  )
}

export const useUserInputForm = (): UserInputFormContextType => {
  return useContext(UserInputFormContext)
}
