import { Box } from '@mui/material'
import { type Criticality } from '../types'
import CriticalityDisplay from './CriticalityDisplay'
import { IssueTitleContainer, IssueTypeTypography } from './IssueTitle.styles'

interface Props {
  idx: number
  title: string | null
  criticality: Criticality | null
}

const IssueTitle: React.FC<Props> = ({ idx, title, criticality }: Props) => {
  return (
    <IssueTitleContainer>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1 }}>
        <IssueTypeTypography>{`${idx + 1}.`}</IssueTypeTypography>
        <CriticalityDisplay criticality={criticality} />
        <IssueTypeTypography>{title ?? 'Deviation'}</IssueTypeTypography>
      </Box>
    </IssueTitleContainer>
  )
}

export default IssueTitle
