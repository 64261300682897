import { Box, CircularProgress, Typography } from '@mui/material'
import CancelButton from './buttons/CancelButton'
import { BackdropContainer, StyledBackdrop, StyledDivider, bottomContainerSx } from './ProgressBackdrop.styles'
import { useIntl } from 'react-intl'

interface Props {
  open: boolean
  msg?: string
  onCancel?: () => void
}

const ProgressBackdrop = ({ open, msg, onCancel }: Props): JSX.Element => {
  const intl = useIntl()
  return <StyledBackdrop open={open}>
    <BackdropContainer>
      <Typography variant="h5" align="center">
        {
          intl.formatMessage({
            id: 'app.progress-backdrop.message',
            defaultMessage: 'Please wait while Copilex is processing your request.'
          })
        }
      </Typography>
      <Typography variant="h6">
        {
          intl.formatMessage({
            id: 'app.progress-backdrop.sub-message',
            defaultMessage: '(This may take a few minutes)'
          })
        }
      </Typography>
      <CircularProgress />
      {
        msg !== undefined &&
        <Box sx={bottomContainerSx}>
          <StyledDivider />
          <Typography variant="h6">
            {msg}
          </Typography>
        </Box>
      }
      {
        onCancel !== undefined &&
        <CancelButton onClick={onCancel} />
      }
    </BackdropContainer>
  </StyledBackdrop>
}

export default ProgressBackdrop
