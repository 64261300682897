import { type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'
import { type ComparativeAnalysisOption, defaultComparativeAnalysisOptions } from '../types'

/**
 * Extracts the comparative analysis options from the user input form, or returns the default options.
 */
const getSelectedComparativeAnalysisOptions = (userInputForm: Partial<UserInputForm>): ComparativeAnalysisOption => (
  (userInputForm.taskParams as AnalysisTaskParams | undefined)?.comparativeAnalysisOptions ?? { ...defaultComparativeAnalysisOptions }
)

export default getSelectedComparativeAnalysisOptions
