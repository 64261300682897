import isPropValid from '@emotion/is-prop-valid'
import styledEmotion from '@emotion/styled'
import { Box, Button, FormControl, type SxProps, type Theme, Typography, styled } from '@mui/material'
import UploadsTable from './UploadsTable'

export const ModalBody = styled(Typography)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2)
}))

interface MainContainerProps {
  uploadDisabled: boolean
}

export const MainContainer = styledEmotion(Box, {
  shouldForwardProp: prop => isPropValid(prop) && prop !== 'uploadDisabled'
})<MainContainerProps>(({ uploadDisabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  // flexBasis: '400px',
  height: '400px',
  alignItems: 'stretch',
  border: '2px dashed',
  borderColor: uploadDisabled ? 'gray' : 'primary.main'
}))

export const StyledFormControl = styled(FormControl)(({ theme }) => ({
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  border: '1px solid',
  borderColor: theme.palette.primary.main
}))

export const Dropzone = styled(Box)(() => ({
  flexGrow: 0,
  flexShrink: 1,
  // minHeight: '200px',
  minWidth: '200px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  ':hover': {
    cursor: 'pointer'
  }
}))

export const DropzoneTypography = styled(Typography)(() => ({
  fontWeight: 'bold',
  fontSize: '1.5em',
  textAlign: 'center',
  color: 'inherit',
  ':hover': {
    cursor: 'pointer',
    textDecoration: 'underline'
  }
}))

export const BottomButtonsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginTop: theme.spacing(2)
}))

export const dropzoneSx = (disabled: boolean): SxProps<Theme> => ({
  borderColor: disabled ? 'gray' : 'primary.main',
  flexGrow: 1
})

export const UploadMoreButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  }
}))

export const StyledUploadsTable = styled(UploadsTable)(() => ({
  flexGrow: 0
}))
