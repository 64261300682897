import { Box, CircularProgress, FormControl, TextField, useTheme } from '@mui/material'
import Loading from 'components/Loading'
import { useAuth, type User } from 'context/AuthContext'
import { useGlobals } from 'context/GlobalsContext'
import { useSession } from 'context/SessionContext'
import { useAttachments } from 'features/documents/api/getAttachments'
import AttachmentsStack from 'features/documents/components/AttachmentsStack'
import FileUploader from 'features/documents/components/FileUploader'
import { type ConfidentialityLevel, type Globals } from 'features/globals/types'
import { updateUserInputFormWithAttachments } from 'features/user-input-form/api/updateUserInputForm'
import { useUserInputForm } from 'features/user-input-form/hooks/useUserInputForm'
import { AnalysisTaskAction, type AnalysisTaskParams, type UserInputForm } from 'features/user-input-form/types'
import type React from 'react'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import ComparativeAnalysisDocumentsSelect from '../../../features/comparative-analysis/components/ComparativeAnalysisDocumentsSelect'
import { getAssistantId } from '../../left-pane/AssistantSelect'
import { AttachmentsAndInputContainer, AttachmentsContainer, LeftSideContainer, SendButtonContainer, composeContainerSx } from './Compose.styles'
import SendIconButton from './SendIconButton'
import UserPromptButtons from './UserPromptButtons'

interface ComposeProps {
  initInput: string
  processing: boolean
  onSubmit: (
    input: string,
    attachmentIds: string[],
    confidentialityLevel: ConfidentialityLevel,
    userInputForm: Partial<UserInputForm>
  ) => void
}

/**
 * Component to compose a message and send it to the assistant.
 */
export const Compose: React.FC<ComposeProps> = (
  { initInput, processing, onSubmit }: ComposeProps
) => {
  const intl = useIntl()
  const theme = useTheme()
  const globals = useGlobals() as Globals
  const currentUser = useAuth() as User
  const { selectedSession: session } = useSession()
  const {
    isPending: isPendingAttachment,
    isError: isErrorAttachment,
    data: attachments,
    error: errorAttachment
  } = useAttachments({ withContent: false, autoRefetch: false })
  const { userInputForm, setUserInputForm } = useUserInputForm()

  const [input, setInput] = useState<string>(initInput)
  const [confidentialityLevel, setConfidentialityLevel] = useState<ConfidentialityLevel | null>(null)

  // When session changes, fetch attachments and confidentiality level
  useEffect(() => {
    console.debug('> Compose [globals, currentUser, session]')

    if (session === null || session === undefined) return

    const level: number = session.settings?.confidentialityLevel ?? globals.defaultLevel.level
    const confidentialityLevel = globals.levels.find((l) => l.level === level)
    if (confidentialityLevel === undefined) throw new Error(`Confidentiality level ${level} not found!`)
    setConfidentialityLevel(confidentialityLevel)
  }, [globals, currentUser, session])

  // When attachments change, update the user input form
  useEffect(() => {
    console.debug('> Compose [attachments]')
    if (isPendingAttachment || isErrorAttachment || attachments === undefined) return

    setUserInputForm(updateUserInputFormWithAttachments(userInputForm, attachments.uploaded))
  }, [attachments])

  useEffect(() => {
    console.debug('> Compose [initInput]')

    setInput(initInput)
  }, [initInput])

  if (session === null || confidentialityLevel === null || isPendingAttachment) {
    return <Loading />
  }

  if (isErrorAttachment) {
    throw errorAttachment
  }

  // Only show user prompt buttons for assistant that support them
  const assistantId = getAssistantId(session, globals)
  const selectedAssistant = globals.assistantsById.get(assistantId)
  if (selectedAssistant === undefined) {
    throw new Error(`Unknown assistant ID: ${assistantId}`)
  }
  const showUserPromptButtons = selectedAssistant.showUserPromptButtons

  // How many rows should the input field have by default.
  // If user prompt buttons are shown, the input field should be smaller
  // to make room for the buttons.
  const minRows = showUserPromptButtons ? 2 : 4
  const maxRows = 4

  const mayReview = !confidentialityLevel.neverReview

  const isValidInput = (input: string, userInputForm: Partial<UserInputForm>): boolean => {
    // Not valid if an upload is in progress
    if (attachments.pending.length > 0) return false
    // Input must be non-empty
    if (input.trim().length > 0) return true
    // Input may be left blank if user has selected a task, and action and some options
    if (userInputForm.mainTask === undefined || userInputForm.mainTask === null) return false
    if (userInputForm.mainTask === 'Analyze') {
      if (attachments.uploaded.length === 0) return false
      const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
      if (taskParams?.action === undefined) return false
      switch (taskParams.action) {
        case AnalysisTaskAction.SanityCheck:
          if (taskParams.sanityCheckOptions === undefined) return false
          break
        case AnalysisTaskAction.ComparativeAnalysis:
          if (
            taskParams.comparativeAnalysisOptions === undefined
          ) {
            return false
          }
          // eslint-disable-next-line no-case-declarations
          const attachmentsIds: Array<string | null> | null = (
            taskParams.comparativeAnalysisOptions?.attachmentsIds ?? null
          )
          if (
            attachmentsIds === null || attachmentsIds.some((id: string | null) => id === null)
          ) {
            return false
          }
          break
        case AnalysisTaskAction.SpecificAnalysis:
          if (taskParams.specificAnalysisOptions === undefined) return false
          break
      }
      return true
    }
    // For Summarize or Translate, input is only required if no attachments are present
    if (userInputForm.mainTask === 'Summarize' || userInputForm.mainTask === 'Translate') {
      if (attachments.uploaded.length === 0) return false
      return true
    }
    return false
  }

  const handleSubmit = (): void => {
    if (!isValidInput(input, userInputForm)) return

    const attachmentIds = attachments.uploaded.map((a) => a.id)

    onSubmit(input, attachmentIds, confidentialityLevel, userInputForm)
    // setInput('');  // Clear input
  }

  /**
   * Generate the placeholder text for the input field
   * based on the current state of the user input form
   * for the Analyze task.
   */
  const getAnalyzePlaceholder = (userInputForm: Partial<UserInputForm>): string => {
    const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
    if (attachments.uploaded.length === 0) {
      return intl.formatMessage({
        id: 'app.text-input.placeholder.upload-document',
        defaultMessage: 'Upload a document first'
      })
    }
    if (taskParams?.action === undefined) {
      return intl.formatMessage({
        id: 'app.text-input.placeholder.select-action',
        defaultMessage: 'Select an action above, or describe what you want to analyze here'
      })
    }
    if (taskParams.comparativeAnalysisOptions !== undefined) {
      // No placeholder needed for comparative analysis, and the text field should be disabled
      return '(No additional instructions needed)'
    }
    if (taskParams.sanityCheckOptions === undefined && taskParams.specificAnalysisOptions === undefined) {
      return intl.formatMessage({
        id: 'app.text-input.placeholder.select-options',
        defaultMessage: 'Select options above, or describe what you want to analyze here'
      })
    }
    return intl.formatMessage({
      id: 'app.text-input.placeholder.additional-instructions',
      defaultMessage: 'Add any additional instructions here, or leave blank'
    })
  }

  /**
   * Generate placeholder text for the input field
   * based on the current state of the user input form.
   */
  const getPlaceholder = (userInputForm: Partial<UserInputForm>): string | undefined => {
    switch (userInputForm.mainTask) {
      case undefined:
      case null:
        return (
          showUserPromptButtons
            ? intl.formatMessage({
              id: 'app.text-input.placeholder.select-task',
              defaultMessage: 'Select a task above, or ask anything'
            })
            : intl.formatMessage({
              id: 'app.text-input.placeholder.type-request',
              defaultMessage: 'Type your request here'
            })
        )
      case 'Analyze':
        return getAnalyzePlaceholder(userInputForm)
      case 'Answer':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-question',
          defaultMessage: 'Type your question here'
        })
      case 'Search':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-search',
          defaultMessage: 'Type what you want to search for here'
        })
      case 'Draft':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-draft',
          defaultMessage: 'Describe what you want to draft here'
        })
      case 'Translate':
        return intl.formatMessage({
          id: 'app.text-input.placeholder.type-translate',
          defaultMessage: 'Type what you want to translate here, and to which language'
        })
      case 'Summarize':
        if (attachments.uploaded.length > 0 || attachments.pending.length > 0) {
          return intl.formatMessage({
            id: 'app.text-input.placeholder.additional-instructions',
            defaultMessage: 'Add any additional instructions here, or leave blank'
          })
        } else {
          return intl.formatMessage({
            id: 'app.text-input.placeholder.type-summarize',
            defaultMessage: 'Type what you want to summarize here, or upload a document'
          })
        }
      default:
        return undefined
    }
  }

  const isDisabledForSelectedTask = (userInputForm: Partial<UserInputForm>): boolean => {
    // Disable input field if the user has selected the Analyze main task
    // with the Comparative Analysis action
    if (userInputForm.mainTask === 'Analyze') {
      const taskParams = userInputForm.taskParams as AnalysisTaskParams | undefined
      return taskParams?.action === AnalysisTaskAction.ComparativeAnalysis
    }
    return false
  }

  const hasTextInput = (
    userInputForm.mainTask !== 'Analyze'
  )
  const isAnalysisTask = (
    userInputForm.mainTask === 'Analyze'
  )
  const isAnalysisTaskSelected = (
    isAnalysisTask &&
    (userInputForm.taskParams as AnalysisTaskParams)?.action !== undefined
  )
  const isComparativeAnalysis = (
    isAnalysisTask &&
    (userInputForm.taskParams as AnalysisTaskParams)?.action === AnalysisTaskAction.ComparativeAnalysis
  )
  const isComparativeAnalysisTypeSelected = (
    isComparativeAnalysis &&
    ((userInputForm.taskParams as AnalysisTaskParams)?.comparativeAnalysisOptions?.comparisonTypeId ?? null) !== null
  )

  // Display attachments container, unless the task is an Analysis task
  // without a selected action, or a Comparative Analysis task (which has
  // its own upload flow)
  const displayAttachmentsContainer = (
    !((isAnalysisTask && !isAnalysisTaskSelected) || isComparativeAnalysis)
  )

  return (
    <Box
      sx={composeContainerSx(theme)}
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={(event) => { event.preventDefault(); handleSubmit() }}
    >
      {/* Everything except the Send button */}
      <LeftSideContainer>

        {/* User prompt buttons */}
        {showUserPromptButtons && (
          <UserPromptButtons />
        )}

        {/* Upload attachment and input field (depending on which task is selected) */}
        <AttachmentsAndInputContainer>
          <FormControl sx={{ width: '100%', gap: 1 }}>

            {
              displayAttachmentsContainer &&
              <AttachmentsContainer>
                {/* Upload attachment */}
                <FileUploader />

                {/* Attachments */}
                <AttachmentsStack attachments={attachments} />
              </AttachmentsContainer>
            }

            {/* Input field */}
            {hasTextInput && <TextField
              id="text-input"
              fullWidth
              multiline
              minRows={minRows}
              maxRows={maxRows}
              value={input}
              disabled={processing || isDisabledForSelectedTask(userInputForm)}
              onChange={(event) => { setInput(event.target.value) }}
              onKeyUp={(event) => {
                if (event.ctrlKey && event.key === 'Enter') { handleSubmit() }
              }}
              placeholder={getPlaceholder(userInputForm)}
            />}
          </FormControl>
        </AttachmentsAndInputContainer>

        {/* UI specific to Comparative Analysis,
            displayed only when a comparison type has been selected
        */}
        {isComparativeAnalysisTypeSelected && (
          <ComparativeAnalysisDocumentsSelect />
        )}
      </LeftSideContainer>

      {/* Send button */}
      <SendButtonContainer>
        {processing && (
          <CircularProgress />
        )}
        {!processing && (
          <SendIconButton
            mayReview={mayReview}
            disabled={!isValidInput(input, userInputForm) || processing}
          />
        )}
      </SendButtonContainer>
    </Box>
  )
}
