import { Button, styled } from '@mui/material'

export const StyledButton = styled(Button)(({ theme }) => ({
  // When hovering the button, change the color to the primary color
  // to make it more visible that it's clickable (it's not obvious
  // since it has no border)
  '&:hover': {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  }
}))
