import { Avatar, SvgIcon } from '@mui/material'
import { AVATAR_SIZE } from 'components/UserAvatar.styles'
import { type AssistantSettings } from 'features/assistants/types'
import { useIntl } from 'react-intl'
import anthropicLogo from '../../../assets/anthropic-logo.png'
import mistralLogo from '../../../assets/mistral-logo.jpeg'
import openaiLogo from '../../../assets/openai-logo.png'
import { useColorMode } from '../../../context/ColorModeContext'

interface Props {
  settings?: AssistantSettings
}

const CopilexIcon = (colorMode: 'dark' | 'light'): JSX.Element => (
  <svg
    width="20.5795"
    height="20.79681"
    viewBox="0 0 20.5795 20.79681"
    fill="none"
    >
    <path
      d="m 0,10.39841 c 0,5.797 4.5652,10.3984 10.2897,10.3984 5.7246,0 10.2898,-4.6014 10.2898,-10.3984 h -3.4058 c 0,4.1304 -2.971,7.21 -6.884,7.21 -3.9129,0 -6.8839,-3.0796 -6.8839,-7.21 0,-4.1304 2.971,-7.21005 6.8839,-7.21005 V 0 C 4.5652,0 0,4.60141 0,10.39841 Z"
      fill={colorMode === 'light' ? '#292524' : '#ffffff'}
      id="path2" />
    <path
      d="m 10.2896,10.39841 c 5.7246,0 10.2897,-4.6014 10.2897,-10.39841 h -3.4057 c 0,4.13041 -2.971,7.21001 -6.884,7.21001 z"
      fill="#0086c9"
      id="path4" />
  </svg>
)

const AIAvatar: React.FC<Props> = ({ settings }: Props): JSX.Element => {
  const intl = useIntl()
  const { colorMode } = useColorMode()

  // FIXME: should refactor the msg settings to contain an assistant id,
  // and not expose the agentType and modelName
  let src
  if (settings !== undefined) {
    if (settings.agentType.startsWith('copilex') || settings.agentType === 'master agent') {
      return (
        <SvgIcon sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}>
          {CopilexIcon(colorMode)}
        </SvgIcon>
      )
    } else {
      if (settings.modelName.startsWith('gpt-')) {
        src = openaiLogo
      } else if (settings.modelName.startsWith('Mistral')) {
        src = mistralLogo
      } else if (settings.modelName.startsWith('Claude')) {
        src = anthropicLogo
      }
    }
  }

  return <Avatar src={src}>
    {
      intl.formatMessage({
        id: 'app.ai-avatar.label',
        defaultMessage: 'AI'
      })
    }
  </Avatar>
}

export default AIAvatar
