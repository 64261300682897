import EditNoteIcon from '@mui/icons-material/EditNote'
import GradingIcon from '@mui/icons-material/Grading'
import SearchIcon from '@mui/icons-material/Search'
import SummarizeIcon from '@mui/icons-material/Summarize'
import TranslateIcon from '@mui/icons-material/Translate'
import ForumIcon from '@mui/icons-material/Forum'
import DifferenceIcon from '@mui/icons-material/Difference'
import TextSnippetIcon from '@mui/icons-material/TextSnippet'
import { MenuItem, Select, Typography, type SelectChangeEvent, Tooltip, Box, Divider } from '@mui/material'
import { type UserInputForm } from 'features/user-input-form/types'
import BackIconButton from '../../buttons/BackIconButton'
import { MenuItemContainer, TaskSelectContainer } from './TaskSelect.styles'
import { useIntl } from 'react-intl'

export interface TaskEntry {
  label: string
  icon: JSX.Element
  userInputForm: Partial<UserInputForm>
  disabled?: boolean
}

// For each task, describe them with a label, icon and partial user input form.
// The partial user input form is used to match the task to the provided user input form,
// and to update the user input form when the task is selected.
export const tasks: TaskEntry[] = [
  {
    label: 'AI Chat',
    icon: <ForumIcon />,
    userInputForm: {
      mainTask: 'Chat'
    }
  },
  {
    label: 'Analyze',
    icon: <GradingIcon />,
    userInputForm: {
      mainTask: 'Analyze'
    }
  },
  {
    label: 'Compare',
    icon: <DifferenceIcon />,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined
    },
    disabled: true
  },
  {
    label: 'Draft',
    icon: <EditNoteIcon />,
    userInputForm: {
      mainTask: 'Draft'
    }
  },
  {
    label: 'Summarize',
    icon: <SummarizeIcon />,
    userInputForm: {
      mainTask: 'Summarize'
    }
  },
  {
    label: 'Translate',
    icon: <TranslateIcon />,
    userInputForm: {
      mainTask: 'Translate'
    }
  },
  {
    label: 'Redact',
    icon: <TextSnippetIcon />,
    userInputForm: {
      // Not yet implemented
      mainTask: undefined
    },
    disabled: true
  },
  {
    label: 'Search',
    icon: <SearchIcon />,
    userInputForm: {
      mainTask: 'Search'
    },
    disabled: true
  }
]

export const mainTaskToLabel = (mainTask: string): string => {
  const task = tasks.find((task) => task.userInputForm.mainTask === mainTask)
  if (task === undefined) {
    throw new Error(`Task with mainTask ${mainTask} not found`)
  }
  return task.label
}

interface Props {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

// Customize the Select component
const TaskSelect: React.FC<Props> = (
  { userInputForm, onChange }: Props
) => {
  const intl = useIntl()
  const selectedLabel = tasks.find((task) => {
    const { mainTask } = userInputForm
    return mainTask !== undefined && task.userInputForm.mainTask === mainTask
  })?.label

  const handleSelectOtherTask = (event: SelectChangeEvent<string>): void => {
    const label = event.target.value
    const task = tasks.find((task) => task.label === label)
    if (task === undefined) {
      throw new Error(`Task with label ${label} not found`)
    }
    onChange({ ...userInputForm, ...task.userInputForm })
  }

  const handleBack = (): void => {
    onChange({
      ...userInputForm,
      mainTask: undefined,
      taskParams: undefined
    })
  }

  const translateLabel = (label: string): string => {
    return intl.formatMessage({
      id: `app.main-task.${label}`,
      defaultMessage: label
    })
  }

  const tooltip = intl.formatMessage({
    id: 'app.task-select.back-button.tooltip',
    defaultMessage: 'Back'
  })

  const contractAnalysisLabel = (
    <Box display="flex" flexDirection={'row'} alignItems={'center'} gap={1}>
      <GradingIcon />
      <Typography key={0}>
        {intl.formatMessage({
          id: 'app.analyze-task.sub-task.Contract analysis',
          defaultMessage: 'Contract analysis'
        })}
      </Typography>
      <Divider orientation="vertical" flexItem />
    </Box>
  )

  // There is an exception for the Analyze task:
  // it has sub-tasks, with only "Contract analysis" available for now.
  // In that case, we show a label indicating the sub-task instead of a Select component.
  const selectComponent = (
    userInputForm.mainTask !== 'Analyze'
      ? <Select value={selectedLabel} onChange={handleSelectOtherTask}>
        {tasks.map(({ label, icon, disabled }, idx) => (
          <MenuItem key={idx} value={label} disabled={disabled ?? false}>
            <MenuItemContainer>
              {icon}
              <Typography variant='body1'>
                {translateLabel(label).toUpperCase()}
              </Typography>
            </MenuItemContainer>
          </MenuItem>
        ))}
      </Select>
      : contractAnalysisLabel
  )

  return (
    <TaskSelectContainer>
      <Tooltip title={tooltip}>
        <BackIconButton onClick={handleBack} />
      </Tooltip>
      {selectComponent}
    </TaskSelectContainer>
  )
}

export default TaskSelect
