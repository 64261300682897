import { Box, Table, TableBody, TableContainer, TableRow, Typography } from '@mui/material'
import MuiMarkdown from 'mui-markdown'
import { type DocReviewIssue, type Evidence, type IssueType, SanityCheckOption } from '../types'
import CorrectionsDisplay from './CorrectionsDisplay'
import CriticalityDisplay from './CriticalityDisplay'
import EvidenceDisplay from './EvidenceDisplay'
import { DetailsCell, LocationCell, StyledBox } from './InternalIssuesDisplay.styles'

interface Props {
  typeToResults: Map<IssueType, DocReviewIssue[]>
}

const InternalIssuesDisplay: React.FC<Props> = ({
  typeToResults
}: Props) => {
  const getOneLocationFromEvidences = (evidences: Evidence[]): string | null => {
    if (evidences.length === 0) {
      return null
    }
    evidences.forEach((evidence) => {
      if (evidence.location !== null && evidence.location !== '') {
        return evidence.location
      }
    })
    return null
  }

  const locationCell = (evidences: Evidence[]): JSX.Element => {
    const location = getOneLocationFromEvidences(evidences)
    return (
      <LocationCell>
        {location ?? 'N/A'}
      </LocationCell>
    )
  }

  return (
    <StyledBox className='internal-issues'>
      {Array.from(typeToResults).map(([type, results]) => {
        // Currently, only Typographical error issues are structured.
        // For other types, we just display the explanation,
        // which contains all the details in Markdown format.
        if (type !== SanityCheckOption.TypographicalErrors) {
          return (
            <div key={type}>
              {results.map((result, idx) => (
                <div key={`result-${idx}`}>
                  <MuiMarkdown>{result.explanation}</MuiMarkdown>
                </div>
              ))}
            </div>
          )
        }
        return (
          <div key={type}>
            <Typography variant='h6'>{type}</Typography>
            <Typography>
              {`Copilex has identified ${results.length} ${type}(s) in the Reviewed Document.`}
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  {
                    results.map((result, idx) => (
                      <TableRow key={idx}>
                        {locationCell(result.evidences)}
                        <DetailsCell>
                          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            {/** Put the corrections if any, otherwise put the explanation */}
                            {
                              result.evidences.map((evidence, idx) => (
                                <EvidenceDisplay
                                  key={idx}
                                  evidence={evidence}
                                  // Do not display the location for internal issues,
                                  // because usually there is only one evidence
                                  // and the location is already displayed in the
                                  // location cell
                                  displayLocation={false}
                                />
                              ))
                            }
                            {
                              result.criticality !== null && result.criticality !== undefined && (
                                <CriticalityDisplay criticality={result.criticality} />
                              )
                            }
                            {
                              // If there are corrections, display them instead of the explanation
                              result.corrections !== null
                                ? <CorrectionsDisplay corrections={result.corrections} />
                                : result.explanation
                            }
                          </Box>
                        </DetailsCell>
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )
      })}
    </StyledBox>
  )
}

export default InternalIssuesDisplay
