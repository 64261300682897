import { SvgIcon, Tooltip, Typography, useTheme, type TypographyProps } from '@mui/material'
import { capitalizeFirstLetter } from 'services/utils'
import { type Criticality } from '../types'

interface Props {
  criticality?: Criticality | null
}

const CriticalityDisplay = ({ criticality }: Props): JSX.Element => {
  const theme = useTheme()

  const CRITICALITY_TO_COLOR: Record<Criticality, string> = {
    low: 'yellow',
    medium: 'orange',
    high: 'red'
  }

  const Flag = (color: string): JSX.Element => (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
      <path d="M0 0h24v24H0z" fill="none"/>
      <path d="M14.4 6L14 4H5v17h2v-7h5.6l.4 2h7V6z" fill={color} stroke={theme.palette.text.primary} />
    </svg>
  )

  const typographyProps: TypographyProps = {
    fontSize: 13,
    fontWeight: 'bold',
    p: 1
  }

  if (criticality === null || criticality === undefined) {
    return <Typography {...typographyProps}>N/A</Typography>
  }

  const flag = criticality !== null && criticality !== undefined ? Flag(CRITICALITY_TO_COLOR[criticality]) : ''
  if (flag === undefined) {
    throw new Error(`Unknown criticality: ${criticality}`)
  }

  return (
    <Tooltip title={capitalizeFirstLetter(`${criticality} Criticality`)}>
      <SvgIcon>
        {flag}
      </SvgIcon>
    </Tooltip>
  )
}

export default CriticalityDisplay
