import { useEffect, useState } from 'react'
import { type AttachmentListItem } from '../types'
import { useAttachments } from './getAttachments'

/**
 * Provides a list of uploaded attachments (excluding pending uploads)
 * for the current session
 */
const useListAttachments = (): AttachmentListItem[] | undefined => {
  const [autoRefetch, setAutoRefetch] = useState(false)
  const { data } = useAttachments({ withContent: false, autoRefetch })

  if (data === undefined) {
    return undefined
  }

  const { uploaded, pending } = data

  // Will refetch automatically (at regular intervals)
  // when there are pending uploads
  useEffect(() => {
    setAutoRefetch(pending.length > 0)
  }, [pending])

  return uploaded
}

export default useListAttachments
