import { type DocType } from 'features/documents'

/**
 * Unique id and label for the type of a comparison (e.g., "Term sheet v. Contract").
 * Predefined by the backend.
 */
export type ComparisonTypeId = string

/**
 * A comparison type is a predefined type of comparison between two documents.
 */
export interface ComparisonType {
  id: ComparisonTypeId
  description: string
  reviewedDocType: DocType
  referenceDocType: DocType
}

export interface ComparativeAnalysisOption {
  /**
   * The ID of the comparison type (e.g., "Term sheet v. Contract").
   * The comparison type is a predefined and the list of comparison types
   * is provided by the API through the `Globals` object.
   * If the comparison type is not yet selected, the ID is `null`,
   * but it should be non-null when sending the form to the server.
   */
  comparisonTypeId: ComparisonTypeId | null

  /**
   * The IDs of the attachments to compare.
   * Has a fixed length of 2: The first attachment is the analyzed document,
   * and the second attachment is the document to compare against the analyzed document.
   * If an attachment is not yet selected, the corresponding ID is `null`.
   * To send it to the server, all IDs should be non-null.
   */
  attachmentsIds: Array<string | null>
}

/**
 * Default values for the comparative analysis options, to be used when
 * initializing the components state.
 */
export const defaultComparativeAnalysisOptions: ComparativeAnalysisOption = {
  comparisonTypeId: null,
  attachmentsIds: [null, null]
}
