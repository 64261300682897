import { Table, TableBody, TableContainer, TableHead, TableRow } from '@mui/material'
import { type AttachmentListItem } from 'features/documents'
import { type Evidence } from '../types'
import EvidenceTableCell from './EvidenceTableCell'
import { StyledHeaderTableCell } from './EvidenceTableCell.styles'
import { useIntl } from 'react-intl'
import { capitalizeFirstLetter } from 'services/utils'

interface Props {
  evidences: Evidence[]
  reviewedDoc: AttachmentListItem | null
  referenceDoc: AttachmentListItem | null
  attachmentIdToAttachmentListItem: Map<string, AttachmentListItem>
}

/**
 * Display relevant passages (evidences) side by side in a table,
 * with those from the reviewed document on the left and those from the reference document on the right.
 */
const ComparisonOverview: React.FC<Props> = ({ evidences, reviewedDoc, referenceDoc, attachmentIdToAttachmentListItem }: Props) => {
  const intl = useIntl()

  const reviewedDocId = reviewedDoc?.id ?? null
  const referenceDocId = referenceDoc?.id ?? null

  /**
   * Separate the evidence for the reference document and the reviewed document,
   * and construct a list of tuples so that they can be displayed side by side.
   */
  const createEvidencePairs = (evidences: Evidence[]): Array<[Evidence | null, Evidence | null]> => {
    // If reviewed and reference document ids are not defined,
    // all evidences are considered to be for the reviewed document
    if (reviewedDocId === null || referenceDocId === null) {
      return evidences.map((evidence) => [evidence, null])
    }

    const reviewedDocEvidences = reviewedDocId !== null ? evidences.filter((evidence) => evidence.docRef?.docId === reviewedDocId) : []
    const referenceDocEvidences = referenceDocId !== null ? evidences.filter((evidence) => evidence.docRef?.docId === referenceDocId) : []

    const pairs: Array<[Evidence | null, Evidence | null]> = []
    for (let i = 0; i < Math.max(reviewedDocEvidences.length, referenceDocEvidences.length); i++) {
      const reviewedDocEvidence = reviewedDocEvidences[i] ?? null
      const referenceDocEvidence = referenceDocEvidences[i] ?? null
      pairs.push([reviewedDocEvidence, referenceDocEvidence])
    }

    return pairs
  }

  const evidencesPairs: Array<[Evidence | null, Evidence | null]> = createEvidencePairs(evidences)

  const formatHeaderText = (whichDoc: 'reviewed' | 'reference'): string => (
    capitalizeFirstLetter(
      intl.formatMessage({
        id: `app.comparison-overview.${whichDoc}-document`,
        defaultMessage: `${whichDoc} document`
      })
    )
  )

  return (
    <TableContainer className='comparison-overview'>
      <Table>
        <TableHead>
          <TableRow>
            <StyledHeaderTableCell>{formatHeaderText('reviewed')}</StyledHeaderTableCell>
            <StyledHeaderTableCell>{formatHeaderText('reference')}</StyledHeaderTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {/* <TableRow key={0}>
            <DocRefDisplay attachment={reviewedDoc} />
            <DocRefDisplay attachment={referenceDoc} />
          </TableRow> */}
          {evidencesPairs.map(([reviewedDocEvidence, referenceDocEvidence], idx) => (
            <TableRow key={idx + 1}>
              <EvidenceTableCell evidence={reviewedDocEvidence} />
              <EvidenceTableCell evidence={referenceDocEvidence} />
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ComparisonOverview
