import { Box, styled, Typography } from '@mui/material'

export const CellContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  padding: 0,
  margin: 0
}))

export const LocationTypography = styled(Typography)(() => ({
  textAlign: 'left',
  fontWeight: 'bold',
  fontSize: 13
}))
