import { Box, TableCell, styled } from '@mui/material'

export const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 1
}))

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  alignItems: 'center'
}))
