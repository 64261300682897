import { CopilexLogo } from './CopilexLogo'
import ToggleColorMode from '../ToggleColorMode'
import { LeftPaneHeaderContainer } from './LeftPaneHeader.styles'

const LeftPaneHeader: React.FC = () => (
  <LeftPaneHeaderContainer>
    <CopilexLogo />
    <ToggleColorMode />
  </LeftPaneHeaderContainer>
)

export default LeftPaneHeader
