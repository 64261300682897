import AttachFileIcon from '@mui/icons-material/AttachFile'
import {
  Box,
  Button,
  Modal,
  Typography
} from '@mui/material'
import Loading from 'components/Loading'
import UserErrorDialog from 'components/UserErrorDialog'
import CloseModalButton from 'components/buttons/CloseModalButton'
import { useDisclosure } from 'hooks/useDisclosure'
import { useState } from 'react'
import { ModalContainer, ModalHeader } from 'theme'
import { useFileUpload } from '../hooks/useFileUpload'
import { BottomButtonsContainer, Dropzone, DropzoneTypography, MainContainer, ModalBody, StyledUploadsTable, UploadMoreButton, dropzoneSx } from './FileUploader.styles'
import { useIntl } from 'react-intl'
import OpenUploadModalButton from './OpenUploadModalButton'

interface Props {
  /**
   * If true, the button will only display an icon and no text.
   */
  iconOnly?: boolean
}

const FileUploader = ({ iconOnly = false }: Props): JSX.Element => {
  const intl = useIntl()
  const { open, close, isOpen } = useDisclosure()
  const [errorMsg, setErrorMsg] = useState<string | null>(null)
  const fileUpload = useFileUpload({
    onError: (error: Error) => { setErrorMsg(error.message) }
  })

  if (fileUpload === undefined) {
    return <Loading />
  }

  const {
    attachmentsAndUploads,
    limits,
    dropzoneState,
    uploadDisabled
  } = fileUpload
  const { getRootProps, getInputProps } = dropzoneState
  const { uploadQuotaRemaining, maxNbPages, maxNbChars } = limits

  if (attachmentsAndUploads === undefined) {
    return <Loading />
  }

  const handleClose = (): void => {
    close()
  }

  const handleCloseModal = (event: any, reason: 'backdropClick' | 'escapeKeyDown'): void => {
    // If the user clicked on the backdrop, don't close the modal
    if (reason === 'backdropClick') {
      return
    }
    handleClose()
  }

  // When there are already many attachments, replace the dropzone with a button.
  // This constants defines the number of attachments after which the dropzone is replaced.
  const hideDropzoneThreshold = 10
  const showDropzone = (
    uploadQuotaRemaining > 0 && attachmentsAndUploads.length < hideDropzoneThreshold
  )
  const showUploadMoreButton = (
    uploadQuotaRemaining > 0 && attachmentsAndUploads.length >= hideDropzoneThreshold
  )

  return (
    <Box>
      <OpenUploadModalButton
        open={open}
        iconOnly={iconOnly}
        uploadDisabled={uploadDisabled}
      />
      <Modal
        open={isOpen}
        onClose={handleCloseModal}
        aria-labelledby="file-upload-modal-title"
        aria-describedby="file-upload-modal-description"
      >
        <ModalContainer sx={{ minWidth: '600px' }}>
          {/* Modal title and close button */}
          <ModalHeader>
            <Typography id="file-upload-modal-title" variant="h4">
              {
                intl.formatMessage({
                  id: 'app.file-uploader-modal.title',
                  defaultMessage: 'Upload documents'
                })
              }
            </Typography>
            <CloseModalButton onClick={handleClose} />
          </ModalHeader>

          {/* Modal elements */}
          <ModalBody>
            <UserErrorDialog
              title={
                intl.formatMessage({
                  id: 'app.file-uploader-modal.upload-failed',
                  defaultMessage: 'Upload failed'
                })
              }
              errorMsg={errorMsg}
              onClose={() => { setErrorMsg(null) }}
            />

            <MainContainer uploadDisabled={uploadDisabled}>
              {/* Table of attachments (uploaded or pending) */}
              <StyledUploadsTable
                attachmentsAndUploads={attachmentsAndUploads}
                limits={limits}
              />

              {/* Dropzone */}
              {showDropzone && <Dropzone
                sx={dropzoneSx(uploadDisabled)}
                {...getRootProps()}
              >
                {/* Display a message if no doc have been uploaded yet */}
                {
                  <DropzoneTypography variant="body1" display="block">
                    {
                      intl.formatMessage({
                        id: 'app.file-uploader-modal.dropzone.message',
                        defaultMessage: 'Click here or drag files to upload'
                      })
                    }
                    <br />
                    <AttachFileIcon />
                  </DropzoneTypography>
                }
                <input {...getInputProps()} />
              </Dropzone>}
            </MainContainer>

            {/* Buttons container */}
            <BottomButtonsContainer>
              {showUploadMoreButton && <UploadMoreButton
                {...getRootProps()}
                disabled={uploadDisabled}
              >
                <AttachFileIcon />
                <Typography variant="body1" display="block" title={
                  intl.formatMessage({
                    id: 'app.file-uploader-modal.upload-more-msg',
                    defaultMessage: 'Max {uploadQuotaRemaining} documents of {maxNbPages} pages or {maxNbChars} characters'
                  }, {
                    uploadQuotaRemaining,
                    maxNbPages,
                    maxNbChars
                  })
                }>
                  <DropzoneTypography variant="body1" display="block">
                    {
                      intl.formatMessage({
                        id: 'app.file-uploader-modal.upload-more-button.label',
                        defaultMessage: 'Upload more'
                      })
                    }
                  </DropzoneTypography>
                </Typography>
                <input {...getInputProps()} />
              </UploadMoreButton>}

              {uploadDisabled && <Typography variant="body2" display="block">
                {
                  // 'We have reached the maximum number of uploads for this session'
                  intl.formatMessage({
                    id: 'app.file-uploader-modal.upload-limit-reached-msg',
                    defaultMessage: 'Upload limit reached'
                  })
                }
              </Typography>}

              <Button
                onClick={handleClose}
                variant="contained"
                sx={{ ml: 'auto', mr: 0 }}
              >
                {
                  intl.formatMessage({
                    id: 'app.file-uploader-modal.done-button.label',
                    defaultMessage: 'Done'
                  })
                }
              </Button>
            </BottomButtonsContainer>
          </ModalBody>
        </ModalContainer>
      </Modal>
    </Box>
  )
}

export default FileUploader
