import AddModeratorIcon from '@mui/icons-material/AddModerator'
import RemoveModeratorOutlinedIcon from '@mui/icons-material/RemoveModeratorOutlined'
import ShieldIcon from '@mui/icons-material/Shield'
import { Tooltip, type SvgIconProps, SvgIcon } from '@mui/material'
import { copilexBlueColor } from '../../../theme'
import { useIntl } from 'react-intl'

export const RuleIcon = (props: SvgIconProps): JSX.Element => (
  <ShieldIcon {...props} />
)

export const ActiveRuleIcon = (props: SvgIconProps): JSX.Element => {
  const intl = useIntl()
  return <Tooltip title={
    // 'Always anonymize this text'
    intl.formatMessage({
      id: 'app.rule-icons.active-rule-icon.tooltip',
      defaultMessage: 'Always anonymize this text'
    })
  }>
    <SvgIcon {...props}>
      <svg
        viewBox='0 0 24 24'
        xmlns='http://www.w3.org/2000/svg'
      >
          <path
            d='M 12 2 L 4 5 v 6.09 c 0 5.05 3.41 9.76 8 10.91 c 4.59 -1.15 8 -5.86 8 -10.91 V 5 Z M 10.94 13.54 L 7.4 10 L 8.81 8.59 l 2.12 2.12 l 4.24 -4.24 l 1.41 1.41 z M 10.94 17.84 l -3.54 -3.54 l 1.41 -1.41 l 2.12 2.12 l 4.24 -4.24 l 1.41 1.41 z'
            id='path2' />
      </svg>
    </SvgIcon>
  </Tooltip>
}

export const AutoRuleIcon = (props: SvgIconProps): JSX.Element => {
  const intl = useIntl()
  return <Tooltip title={
    intl.formatMessage({
      id: 'app.rule-icons.auto-rule-icon.tooltip',
      defaultMessage: 'Let AI decide whether to anonymize'
    })
  }>
    <SvgIcon {...props}>
      <svg
        viewBox='0 0 48 48'
        xmlns='http://www.w3.org/2000/svg'
      >
          <path
            d='M 24 4 L 8 10 v 12.18 c 0 10.1 6.82 19.52 16 21.82 c 9.18 -2.3 16 -11.72 16 -21.82 V 10 Z M 17 23 l -6 -3 l 6 -3 l 3 -6 l 3 6 l 6 3 l -6 3 l -3 6 z M 26 32 l -4 -2 l 4 -2 l 2 -4 l 2 4 l 4 2 l -4 2 l -2 4 z'
            id='path2' />
      </svg>
    </SvgIcon>
  </Tooltip>
}

/**
 * Icon for the "auto" rule, colored in gatewai blue with white stars
 */
export const AutoRuleColoredIcon = (props: SvgIconProps): JSX.Element => {
  const intl = useIntl()
  return <Tooltip title={
    intl.formatMessage({
      id: 'app.rule-icons.auto-rule-icon.tooltip',
      defaultMessage: 'Let AI decide whether to anonymize'
    })
  }>
    <SvgIcon {...props}>
      <svg
        viewBox='0 0 8.46667 10.583334'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g
          id='layer1'
          transform='translate(-138.0179,-89.910066)'>
          <path
            d='m 142.25124,89.910066 -4.23334,1.5875 v 3.222625 c 0,2.672292 1.80446,5.164667 4.23334,5.773209 2.42887,-0.608542 4.23333,-3.100917 4.23333,-5.773209 v -3.222625 z'
            id='path1'
            style={{ fill: copilexBlueColor }}/>
          <path
            d='m 140.51036,95.089682 -1.5875,-0.79375 1.5875,-0.79375 0.79375,-1.587501 0.79375,1.587501 1.5875,0.79375 -1.5875,0.79375 -0.79375,1.5875 z m 2.38125,2.38125 -1.05833,-0.52916 1.05833,-0.52917 0.52917,-1.05833 0.52917,1.05833 1.05833,0.52917 -1.05833,0.52916 -0.52917,1.05834 z'
            style={{ fill: '#ffffff' }}
            id='path2' />
        </g>
      </svg>

    </SvgIcon>
  </Tooltip>
}

export const ExcludeRuleIcon = (props: SvgIconProps): JSX.Element => {
  const intl = useIntl()
  return <Tooltip title={
    intl.formatMessage({
      id: 'app.rule-icons.exclude-rule-icon.tooltip',
      defaultMessage: 'Don\'t anonymize this text'
    })
  }>
    <RemoveModeratorOutlinedIcon {...props}/>
  </Tooltip>
}

export const AddRuleIcon = (props: SvgIconProps): JSX.Element => (
  <AddModeratorIcon {...props} />
)
