import { type AttachmentListItem } from 'features/documents'
import { type UserInputForm } from '../types'

/**
 * Update the user input form based on the given uploaded attachments.
 */
export const updateUserInputFormWithAttachments = (
  userInputForm: Partial<UserInputForm>,
  attachments: AttachmentListItem[]
): Partial<UserInputForm> => {
  // User input form has a docType field, which is used to determine
  // whether a contract is attached or not.
  // FIXME: This field is deprecated and should be removed.
  const docType = (
    attachments.length > 0
      ? attachments[0].docType
      : undefined
  )

  return { ...userInputForm, docType }
}
