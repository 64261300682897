import { useContext } from 'react'
import ColorModeContext from '../../context/ColorModeContext'
import { Tooltip } from '@mui/material'
import { useIntl } from 'react-intl'

const HELP_PAGE_URL_BY_LOCALE: Record<string, string> = {
  en: 'https://gatewai.notion.site/User-Help-Page-42ec93be1d064e25956a86e947ad5bf3',
  fr: 'https://gatewai.notion.site/Assistance-Aide-7e1f58f9429c4e18923171daecf2825f'
}

export const openHelpPage = (locale: string): void => {
  window.open(
    HELP_PAGE_URL_BY_LOCALE[locale] ?? HELP_PAGE_URL_BY_LOCALE.en,
    '_blank'
  )
}

export const CopilexLogo = (): JSX.Element => {
  const intl = useIntl()
  const { colorMode } = useContext(ColorModeContext)

  const handleClick = (): void => {
    openHelpPage(intl.locale)
  }

  const alt = intl.formatMessage({
    id: 'app.copilex-logo-alt',
    defaultMessage: 'Copilex logo'
  })

  const tooltip = intl.formatMessage({
    id: 'app.copilex-logo-tooltip',
    defaultMessage: 'Click to open help page'
  })

  return (
    <Tooltip title={tooltip}>
      <img
        src={`/logo-full-${colorMode}.svg`}
        alt={alt}
        style={{
          width: 'auto',
          height: '32px',
          paddingLeft: '6px',
          paddingRight: '6px',
          // Show that the logo is clickable
          cursor: 'pointer'
        }}
        onClick={handleClick}
      />
    </Tooltip>
  )
}
