import { type Globals } from 'features/globals'
import { type ComparisonTypeId } from '../types'
import { type DocType } from 'features/documents'

const getDocTypesFromComparisonType = (
  comparisonTypeId: ComparisonTypeId,
  globals: Globals
): DocType[] => {
  const comparisonTypes = globals.taskParams.analyze.comparativeAnalysis.comparisonTypes
  // Find the comparison type with the given ID
  const comparisonType = comparisonTypes.find((type) => type.id === comparisonTypeId)
  if (comparisonType === undefined) {
    throw new Error(`Comparison type not found: ${comparisonTypeId}`)
  }
  return [comparisonType.reviewedDocType, comparisonType.referenceDocType]
}

export default getDocTypesFromComparisonType
