import { Tooltip } from '@mui/material'
import { tasks } from './TaskSelect'
import { type UserInputForm } from 'features/user-input-form/types'
import { OutlinedButton } from '../../buttons/OutlinedButton'
import { StyledBox } from './TaskButtons.styles'
import { useIntl } from 'react-intl'
import AnalyzeTaskMenu from './AnalyzeTaskMenu'

interface TaskButtonProps {
  label: string
  icon: React.ReactNode
  disabled: boolean
  onClick: () => void
}

const TaskButton: React.FC<TaskButtonProps> = (
  { label, icon, disabled, onClick }: TaskButtonProps
) => {
  const intl = useIntl()

  const translatedTaskLabel = intl.formatMessage({
    id: `app.main-task.${label}`,
    defaultMessage: label
  })

  const tooltip = (
    disabled
      ? intl.formatMessage({
        id: 'app.task-button.coming-soon',
        defaultMessage: 'Coming soon...'
      })
      : intl.formatMessage(
        {
          id: 'app.task-button.click-to-perform-task',
          defaultMessage: 'Click to perform a {task} task'
        },
        { task: translatedTaskLabel }
      )
  )

  return (
    <Tooltip title={tooltip} placement={'top'}>
      <span>
        <OutlinedButton
          variant={'outlined'}
          startIcon={icon}
          disabled={disabled ?? false}
          onClick={onClick}
        >
          {translatedTaskLabel}
        </OutlinedButton>
      </span>
    </Tooltip>
  )
}

interface TaskButtonsProps {
  userInputForm: Partial<UserInputForm>
  onChange: (userInputForm: Partial<UserInputForm>) => void
}

/**
 * Buttons for selecting a task (e.g. "Analyze", "Draft", ...)
 */
const TaskButtons: React.FC<TaskButtonsProps> = (
  { userInputForm, onChange }: TaskButtonsProps
) => {
  return (
    <StyledBox>
      {tasks.map(({ label, icon, disabled, userInputForm: buttonUserInputForm }, idx) =>
        (
          // Each task can be selected by pressing its button,
          // with the exception of "Analyze" that shows the dropdown menu to select more options in one go.
          label === 'Analyze'
            ? <AnalyzeTaskMenu key={idx} label={label} icon={icon} disabled={disabled ?? false} />
            : <TaskButton
                key={idx}
                label={label}
                icon={icon}
                disabled={disabled ?? false}
                onClick={() => { onChange({ ...userInputForm, ...buttonUserInputForm }) }}
              />
        )
      )}
    </StyledBox>
  )
}

export default TaskButtons
