import { Box, styled } from '@mui/material'
import { createTheme, responsiveFontSizes, type Theme, type ThemeOptions } from '@mui/material/styles'

export const copilexBlueColor = '#0086c9'

export const getThemeOptions = (colorMode: 'light' | 'dark'): ThemeOptions => (
  {
    palette: {
      mode: colorMode,
      primary: {
        main: '#0086c9'
      },
      secondary: {
        main: '#f99300'
      },
      background: {
        default: colorMode === 'light' ? '#f5f5f5' : '#1c1c1c',
        paper: colorMode === 'light' ? 'white' : '#2a2a2a'
      }
    },
    typography: {
      fontFamily: '"Geologica", sans-serif'
    },
    components: {
      MuiTab: {
        styleOverrides: {
          root: {
            color: 'gray',
            '&.Mui-selected': {
              color: colorMode === 'light' ? 'black' : 'white'
            }
          }
        }
      }
    }
  }
)

export const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor: theme.palette.background.paper,
  maxWidth: '1200px',
  padding: theme.spacing(2),
  gap: theme.spacing(1),
  width: 'auto',
  height: 'auto',
  border: `2px solid ${theme.palette.primary.main}`,
  borderRadius: theme.spacing(1),
  [theme.breakpoints.only('xs')]: {
    width: '100%',
    height: '100%',
    gap: 0,
    border: '0px',
    borderRadius: 0
  },
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}))

export const ModalHeader = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  alignSelf: 'stretch'
}))

// Prevents scrollbar from always showing up in Chrome
export const scrollbarFix = {
  scrollbarGutter: 'stable',
  overflowY: 'hidden',
  ':hover': { overflowY: 'auto' }
}

export const getTheme = (colorMode: 'light' | 'dark'): Theme => (
  responsiveFontSizes(createTheme(getThemeOptions(colorMode)))
)
