import { type SxProps, type Theme } from '@mui/material'

export const iconButtonSx = (enabled: boolean, palette: Theme['palette']): SxProps<Theme> => ({
  borderRadius: 0,
  backgroundColor: enabled ? palette.primary.main : 'none',
  color: enabled ? palette.primary.contrastText : palette.primary.main,
  fill: enabled ? palette.primary.contrastText : palette.primary.main,
  '&:hover': {
    backgroundColor: enabled ? palette.primary.dark : 'none'
  }
})

export default iconButtonSx
